@import "swiper/scss";
@import "swiper/scss/navigation";
@import "swiper/scss/pagination";

@import "~@ng-select/ng-select/themes/default.theme.css";

/* You can add global styles to this file, and also import other style files */
@import "/src/variables.scss";
@import "/src/fonts.scss";

.swiper-button-next,
.swiper-button-prev {
  color: $primary80;
}

input::-webkit-inner-spin-button {
  display: none;
}

html {
  color: $primary;
}

.flex {
  display: flex;
}

.space-between {
  justify-content: space-between;
}

.flex-center {
  @include flex-center;
}

.flex-start {
  align-items: flex-start;
}

.justify-start {
  justify-content: flex-start !important;
}

.flex-right {
  @include flex-right;
}

.flex-left {
  @include flex-left;
}

.inline-flex {
  display: inline-flex;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-grow-1 {
  flex-grow: 1;
}

.bold {
  font-weight: bold;
}

.margin-bottom-6 {
  margin-bottom: 6px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-bottom-16 {
  margin-bottom: 16px;
}

.margin-bottom-18 {
  margin-bottom: 18px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-24 {
  margin-bottom: 20px;
}

.margin-bottom-36 {
  margin-bottom: 36px;
}

.margin-top-4 {
  margin-top: 4px !important;
}

.margin-top-8 {
  margin-top: 8px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-top-16 {
  margin-top: 16px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-24 {
  margin-top: 24px;
}

.margin-top-28 {
  margin-top: 28px;
}

.margin-top-30 {
  margin-top: 30px;
}

.padding-top-36 {
  padding-top: 36px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.flex-no-wrap {
  flex-wrap: nowrap !important;
}

.transform-rotate-180 {
  transform: rotate(180deg);
}

.position-relative {
  position: relative;
}

//text
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  overflow-wrap: break-word;
  // font-family: 'lato', 'arial', sans-serif;
  font-family: "Lato";
  font-style: normal;
  letter-spacing: -0.01em;
  // color: $primary;

  //scrollbar start
  scrollbar-width: thin;
  scrollbar-color: $primary40 transparent;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary40;
    border-radius: 6px;
    border: 3px solid transparent;
  }

  &::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }

  //scrollbar end
}

h1 {
  font-weight: 900;
  font-size: 36px;
  line-height: 110%;

  // color: white;
  /* identical to box height, or 40px */
  &.gold-bg {
    background: linear-gradient(156.63deg, #c3a46b 0%, #a8884c 67.05%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}

.h2 {
  font-weight: 900;
  font-size: 24px;
  line-height: 130%;
  /* or 31px */
}

h2 {
  @extend .h2;
  color: $primary;
}

h3 {
  font-weight: 800;
  font-size: 20px;
  line-height: 130%;

  /* identical to box height, or 26px */
}

h4 {
  /* H4 */
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.11em;
  text-transform: uppercase;
}

h5 {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */
  letter-spacing: 0.11em;
  text-transform: uppercase;
}

.p-styling {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.01em;
}

p {
  @extend .p-styling;
}

.p-light {
  font-weight: 300 !important;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.01em;
}

.subscription {
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: $primary80;
}

.direction-column {
  flex-direction: column;
}

.gap-5 {
  gap: 5px;
}

.gap-7 {
  gap: 7px;
}

.gap-12 {
  gap: 12px;
}

.gap-16 {
  gap: 16px;
}

.flex-basis-100 {
  flex-basis: 100%;
}

.checkbox-input,
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  height: 36px;
  width: 36px;
  min-width: 36px;
  border-radius: 6px;
  /* For iOS < 15 to remove gradient background */
  border: 3px solid $primary10;
  background-color: transparent;
  /* Not removed via appearance */
  margin: 0;
  position: relative;

  &:checked {
    &::before {
      content: " ";
      background-color: $primary90;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L15 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      border-radius: 4px;
      position: absolute;
    }
  }

  &.list-checkbox {
    border-radius: 4px;
    border: 2px solid $primary60;
    width: 24px;
    height: 24px;
    min-width: 24px;
    background-color: $primary80;
    appearance: none;
    position: relative;

    &:checked {
      &::before {
        background-color: $primary90;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 7L5 11L15 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: $secondary;
        border-radius: 2px;
      }
    }
  }
}

label {
  @extend .p-styling;

  &.checkbox {
    display: flex;
    gap: 13px;
    align-items: center;

    input {
      @extend .checkbox-input;
    }
  }
}

.alert {
  color: $fail !important;
}

.success {
  color: $success !important;
}

a,
.a {
  @include link;
}

.text-decoration-none {
  text-decoration: none;
}

.input-container {
  display: flex;
  gap: 7px;
  flex-direction: column;
}

ng-select {
  .ng-clear-wrapper {
    height: 17px;
    width: 17px;
    background-size: 15px auto;
    background-position: right;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17 9V18.4C17 18.5591 16.9368 18.7117 16.8243 18.8243C16.7117 18.9368 16.5591 19 16.4 19H3.6C3.44087 19 3.28826 18.9368 3.17574 18.8243C3.06321 18.7117 3 18.5591 3 18.4V9' stroke='%23A84C4C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 15V9' stroke='%23A84C4C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12 15V9' stroke='%23A84C4C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 5H14M19 5H14H19ZM1 5H6H1ZM6 5V1.6C6 1.44087 6.06321 1.28826 6.17574 1.17574C6.28826 1.06321 6.44087 1 6.6 1H13.4C13.5591 1 13.7117 1.06321 13.8243 1.17574C13.9368 1.28826 14 1.44087 14 1.6V5H6Z' stroke='%23A84C4C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

    & > .ng-clear {
      display: none !important;
    }
  }

  &.hide-delete-button {
    .ng-clear-wrapper {
      display: none;
    }
  }
}

.ng-select.ng-select-single,
.ng-select.ng-select-multiple {
  .ng-select-container {
    height: 49px;
    background-color: $primary10;
    border-radius: 12px;
    color: $primary;
    border: none;

    .ng-value-container .ng-input {
      top: 14px;
    }
  }
}

input[type="email"],
input[type="password"],
input[type="text"],
input[type="time"],
input[type="number"],
input[type="date"],
input[type="tel"] {
  border: none;
  padding: 16px;
  border-radius: 12px;
  width: 100%;
  background: $primary10;
  font-size: 16px;

  &::placeholder {
    color: $primary60;
    font-weight: 300;
    font-size: 16px;
  }
}

// input[type="date"]{
//   padding: 0;
//   height: 49px;
//   background-color: $white;
//   -webkit-datetime-edit-fields-wrapper{
//   }

// }

// ::-webkit-datetime-edit-fields-wrapper{
//   height: 100%;;
//   display: flex;
//   justify-content: stretch;
// }
// ::-webkit-datetime-edit{
//   height: 100%;
// }
// ::-webkit-datetime-edit-year-field{
//   background: $primary10;
//   height: 100%;
// }
.uppercase {
  text-transform: uppercase;
}

.gold-link {
  font-size: 12px;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0.41em;
  color: $secondary;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 6px;
}

// inputs start
textarea {
  border-radius: 12px;
  background-color: $primary10;
  width: 100%;
  resize: vertical;
  padding: 24px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $primary;
  border: none;
}

select {
  -webkit-appearance: none;
  border: none;
  border-radius: 12px;
  background-color: $primary10;
  width: 100%;
  resize: vertical;
  padding: 11px 30px 11px 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $primary;
  appearance: none;
  position: relative;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23A8884C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: calc(100% - 8px) center;

  &:focus {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' transform='scale(-1 -1)' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23A8884C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  height: max-content;

  input {
    display: none;
  }

  label {
    display: inline-block;
    background-color: $primary10;
    padding: 12px 9px;
    color: $primary;
    border-radius: 12px;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
  }

  input:checked + label {
    background-color: $primary;
    color: $white;
  }

  &.modal-tags {
    label {
      background-color: white;
    }
  }
}

.card {
  background-color: $primary10;
  // @extend .p-light;
  color: $primary;
  padding: 16px;
  border-radius: 12px;
}

ngx-daterangepicker-material {
  position: relative;
}

.md-drppicker {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;

  @media (max-width: $mobile) {
    flex-direction: column;
    flex-wrap: wrap;
  }

  left: unset !important;
  right: 20px !important;

  .available {
    span {
      color: $primary;
    }
  }

  padding: 0 !important;

  * {
    transition: all 0.4s !important;
  }

  &.shown {
    padding: 4px !important;

    .buttons {
      padding: 5px 15px 10px 15px;
      text-align: unset;

      .buttons_input {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;

        button {
          color: white;
          flex-grow: 1;
          border-radius: 6px;
          background: linear-gradient(156.63deg, #c3a46b 0%, $secondary 67.05%)
            border-box !important;
          border: 2px solid transparent;

          &:hover {
            border: 2px solid transparent;
            background: linear-gradient($white, $white) padding-box,
              linear-gradient(156.63deg, #c3a46b 0%, $secondary 67.05%)
                border-box !important;
            color: $secondary;
          }
        }
      }
    }
  }

  .start-date,
  .end-date {
    background-color: $secondary !important;
  }

  .calendar-table {
    .next,
    .prev {
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23A8884C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") !important;
      transform: rotate(90deg) !important;
      background-size: 12px !important;
    }

    .next {
      transform: rotate(-90deg) !important;
    }
  }
}

.add-media-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 76px);
  gap: 15px;

  .preview-container {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    height: 76px;

    img {
      border-radius: 12px;
      object-fit: cover;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }

    .delete-btn {
      z-index: 2;
      position: absolute;
      color: #000;
      background-color: white;
      height: 16px;
      width: 16px;
      text-align: center;
      right: 5px;
      top: 5px;
      border-radius: 50%;
      opacity: 0.7;
      cursor: pointer;
      box-shadow: 0px 0px 3px 0px #000;
      font-size: 10px;
      @extend .flex-center;
    }
  }

  app-enviado-button {
    display: inline-block;
    width: auto;
    margin: 0px 0px;
  }

  .spinner:before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid transparent;
    border-top-color: var(---env-color-primary);
    border-bottom-color: var(---env-color-primary);
    animation: spinner 1.6s ease infinite;
  }
}

// inputs end

//table start
.table-wrapper {
  .flex-title {
    display: flex;
    gap: 8px;
    @include flex-center;
    margin-right: 20px;
  }

  padding: 26px 16px 21px 16px;
  background-color: $primary90;
  border-radius: 6px;
  max-width: calc(var(--dvw100) - 40px);
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  transition: background-image 0.2s ease-in-out;
  -webkit-transition: background-image 0.2s ease-in-out;

  h4 {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 24px;
    row-gap: 12px;
  }
}

table {
  margin-top: 18px;
  width: 100%;
  overflow-x: auto;
  max-width: 100%;
  color: $white;
  border-collapse: collapse;

  thead,
  tbody {
    tr {
      th {
        padding-bottom: 18px;
        @extend .p-light;

        & > div {
          justify-content: center;
        }

        &:last-of-type {
          text-align: right;
        }

        &:last-of-type > div {
          justify-content: flex-end;
        }

        &:first-of-type {
          text-align: left;
        }

        &:first-of-type > div {
          justify-content: flex-start;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 1px solid $primary80;
        // border-bottom: 1px solid $primary80;
        padding: 16px 0;
        text-align: center;

        &:first-of-type {
          text-align: left;
        }

        &:last-of-type {
          text-align: right;
        }
      }
    }
  }

  thead,
  tbody {
    tr {
      th,
      td {
        padding-right: 5px;

        &:last-child {
          padding-right: unset;
        }
      }
    }
  }

  app-progress-bar {
    max-width: 180px;
    margin-left: auto;
  }

  .vorgestellt {
    padding-top: 16px;
    border-top: 1px solid $primary80;
  }
}

.admin-mode {
  @media (max-width: $mobile) {
    table {
      th,
      td {
        white-space: nowrap;

        &:not(:first-of-type) {
          padding-left: 10px;
        }
      }
    }
  }
}

//table end

ol,
ul {
  padding: 18px 22px;
  list-style-position: outside;

  li {
    padding-left: 9px;
    line-height: 24px;
    margin-bottom: 6px;
  }
}

.primary-badge {
  height: 24px;
  width: 24px;
  @include flex-center;
  background-color: $primary;
  border-radius: 50%;
  font-weight: 700;
  font-size: 14px;
  line-height: 10px;

  &.secondary {
    background-color: $secondary;
  }
}

.white {
  color: $white !important;
}

.underline {
  text-decoration: underline;
}

.primary10 {
  color: $primary10 !important;
}

.white-bg {
  background-color: $white;
}

.primary40 {
  color: $primary40 !important;
}

.primary60 {
  color: $primary60 !important;
}

.primary {
  color: $primary !important;
}

.secondary {
  color: $secondary !important;
}

.host-full-width {
  max-width: 100%;
  display: flex;
  flex-grow: 1;

  & > div {
    display: flex;
    flex-grow: 1;
    max-width: 100%;
  }
}

.host-full-width:not(
    page-meine-projekte,
    page-arbeitskraefte,
    page-edit,
    page-vorlagen
  ) {
  @media (min-width: $size_no_right_outlet) {
    .main {
      max-width: 1000px;
    }
  }
}

.width-100 {
  width: 100%;
}

.width-0 {
  width: 0%;
}

.main {
  // padding: 46px 46px 0px 46px;
  padding: 50px 46px 0px 46px;
  flex-basis: 600px;

  @media (min-width: $size_no_right_outlet) {
    min-width: 600px;
  }

  display: flex;
  flex-direction: column;
  gap: 36px;
  // height: var(--dvh100);
  overflow-y: auto;

  &.no-detail {
    flex-grow: 1;
  }

  .main-head {
    display: flex;
    gap: 50px;
    flex-wrap: wrap;

    app-svgs {
      cursor: pointer;
    }

    .left,
    .right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: 34px;

      .with-back-btn {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }

    .right {
      justify-content: flex-end;
      align-items: flex-end;
    }

    &.logo-with-back-button {
      .left {
        position: relative;

        app-svgs {
          position: absolute;
          left: 0px;
        }

        div {
          flex-grow: 1;
        }

        h1 {
          // because of button padding
          padding-left: 17px;
          padding-top: 8px;
        }
      }
    }
  }
}

.detail {
  // *{
  //   color: $primary;
  // }
  padding: 68px 46px 20px 46px;
  flex-grow: 1;
  background-image: url(/assets/detail_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  height: var(--dvh100);
  overflow-y: auto;

  .logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    .logo {
      height: 100px;
      width: auto;
    }
  }
}

.notiflix-confirm-content {
  border-radius: 12px !important;
}

.nx-confirm-button-cancel {
  background: linear-gradient(156.63deg, #c3a46b 0%, $secondary 67.05%)
    border-box !important;
  color: $white !important;
}

.nx-confirm-button-ok {
  background: linear-gradient(156.63deg, $primary40 0%, $primary40 67.05%)
    border-box !important;
  color: $white !important;
}

.notiflix-confirm-head {
  h5 {
    color: $secondary !important;
  }
}

.notiflix-report-content {
  border-radius: 12px !important;
}

.notiflix-report-button {
  background: linear-gradient(156.63deg, #c3a46b 0%, $secondary 67.05%)
    border-box !important;
  color: $white !important;
}

.notiflix-report-head {
  h5 {
    color: $secondary !important;
  }
}

.toggle-component {
  // coloring

  &.white-gold {
    background-color: $primary20;

    .toggle {
      color: $primary;
      cursor: pointer;

      &.selected {
        svg {
          path {
            stroke-width: 3;
            stroke: $secondary;
          }
        }
      }
    }
  }

  &.darkblue-blue {
    .toggle {
      svg {
        path {
          stroke: $white;
        }
      }
    }
  }

  .toggle.disabled {
    cursor: default;
    pointer-events: none;
    color: $primary40 !important;

    svg {
      path {
        stroke: $primary40;
      }
    }
  }
}

.sidebar-nav {
  .bottom span.activated {
    border-radius: 12px;
    background-color: $white;
    color: $secondary;
    @include shadowS;

    svg {
      path {
        stroke-width: 3;
        stroke: $secondary;
      }
    }
  }
}

app-editor {
  table * {
    color: $primary;
  }

  .note-editor {
    background-color: $white;
    border-radius: 12px;

    .note-toolbar {
      width: calc(100% - 40px);
      max-width: 380px;
      z-index: 2;
      position: absolute;
      bottom: 20px;
      right: 20px;
      border-radius: 12px;
      background-color: $primary;

      // max-width: calc(100% - 40px);
      .note-btn {
        border: none;
        background-color: $primary;
        color: $white;

        svg {
          path {
            stroke: $white;
          }
        }

        &.active {
          color: $secondary;

          svg {
            path {
              stroke: $secondary;
            }
          }
        }
      }

      .note-dropdown-menu {
        background-color: $primary;

        & * {
          color: $white;
        }
      }
    }
  }

  .note-codable {
    border-radius: 12px 12px 0px 0px !important;
    padding-bottom: 100px !important;
  }

  .note-editable {
    padding-bottom: 100px !important;
  }
}

quill-editor {
  position: relative;
  display: block;
  // color: $white;

  .ql-editor {
    background-color: $white;
    border-radius: 12px;
    border: none;
    height: 415px;
    overflow-y: auto;
  }

  .ql-container {
    border: none !important;
  }

  .ql-toolbar {
    z-index: 1;
    bottom: 20px;
    right: 20px;
    position: absolute;
    background-color: $primary90;
    color: $white;
    border-radius: 12px;

    .ql-formats {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 0px;
    }

    .ql-picker-label,
    .ql-picker-item {
      &::before {
        color: $white;
      }
    }

    .ql-fill {
      fill: $white;
    }

    .ql-stroke {
      stroke: $white;
    }

    .ql-active {
      .ql-fill {
        fill: $secondary !important;
      }

      .ql-stroke {
        stroke: $secondary !important;
      }
    }

    .ql-picker-options {
      background-color: $primary;
    }
  }
}

.vertrag-erstellen {
  quill-editor {
    .ql-editor {
      min-height: 500px;
    }
  }
}

@media (max-width: $size_no_right_outlet) {
  .main {
    flex-grow: 1;
  }

  .detail {
    display: none;
  }
}

@media (max-width: $size_nosidebar) {
  .content {
    flex-direction: column;
  }

  // .content-header-mobile {
  //   display: flex;
  // }
  .main {
    width: var(--dvw100);
    max-width: 100%;
    flex-basis: 100%;
    min-width: unset;
    overflow-x: auto;
    // max-width: var(--dvw100);

    padding-top: 36px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 160px;

    &.content-full-width {
      padding-left: 0px;
      padding-right: 0px;

      & > *:not(.content) {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
}

app-apply {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  app-data-collection-wrapper:last-of-type {
    .data-collection-wrapper {
      padding-bottom: 130px;
    }
  }

  &.padding-bottom-160 {
    app-data-collection-wrapper:last-of-type {
      .data-collection-wrapper {
        padding-bottom: 160px;
      }
    }
  }
}

@media (max-width: $mobile) {
  app-apply {
    .main {
      padding-left: 0px;
      padding-right: 0px;
    }

    app-header-w-backbtn-logo {
      padding: 0 22px;
    }

    h1:not(.no-mobile-padding) {
      padding-left: 22px;
    }
  }
}

app-mehr-infos {
  max-height: 100%;
}

app-job-detail {
  flex-grow: 1;
}

app-modal,
.app-modal {
  overflow: hidden;
  z-index: 4;
  height: var(--dvh100);
  width: var(--dvw100);
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(60, 75, 93, 0.5);
  // pointer-events: none;
  pointer-events: all;

  .header {
    h4 {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: $primary40;
      margin-bottom: 5px;
      margin-top: 0px;
    }

    margin-bottom: 20px;
  }

  .cm-wrap::after {
    right: 10px;
    background-color: $white;
  }

  .disabled {
    .cm-wrap::after {
      background-color: $primary10;
      color: $primary;
    }
  }

  .checkbox {
    input {
      background-color: $primary20 !important;
    }
  }

  @extend .flex-center;

  select {
    background-color: white;
  }

  .swipe-wrap {
    max-width: calc(100% - 140px);
    opacity: 0;

    //TODO: with transition on -> width problem. fix if time left
    // transition: opacity 0.2s ease-in-out;
    &:not(.width-0) {
      // transition: opacity 0.2s ease-in-out;
      width: 100%;
      opacity: 1;
    }
  }

  .modal-inner {
    pointer-events: all;
    z-index: 3;
    max-height: calc(var(--dvh100) - 40px);
    max-width: min(1400px, calc(var(--dvw100) - 40px));
    overflow: auto;

    // @include shadowS;
    -webkit-box-shadow: 0px 0px 23px 3px rgba(60, 75, 93, 0.84);
    box-shadow: 0px 0px 23px 3px rgba(60, 75, 93, 0.84);
    // -webkit-box-shadow: 0px 0px 23px 3px rgba(0,0,0,0.84);
    // box-shadow: 0px 0px 23px 3px rgba(0,0,0,0.84);
    // max-width: 1050px;
    // padding: 24px 16px;
    padding: 40px 35px 50px;
    width: 95%;
    background-color: $primary10;
    border-radius: 12px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;

    app-bewerbercheck-modal {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 100%;
      overflow-y: auto;
    }

    input {
      background-color: $white;
    }

    .close-btn {
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      top: 20px !important;
      right: 20px !important;
      left: unset !important;
      width: 60px !important;
      height: 60px !important;
      z-index: 1;
      // background-color: $white;
      @extend .flex-center;
    }

    &.max-width-1200 {
      width: 1200px;
      max-width: 1200px;
    }

    &.width-100 {
      width: 100%;
      max-width: 100% !important;
    }

    .scroll-container {
      overflow-y: auto;
      padding-right: 6px;

      @media (max-width: $mobile) {
        // min-height: 450px;
      }
    }

    @media (max-width: $mobile) {
      padding: 16px;
    }
  }

  &.smaller-modal {
    .modal-inner {
      max-width: 1050px;
    }
  }

  .navigation {
    .left-nav,
    .right-nav {
      position: absolute;
      top: calc(50% - 34px);
      color: $white;
      height: 68px;
      width: 68px;
      background-image: url("data:image/svg+xml,%3Csvg width='68' height='68' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='m42.5 17-17 17 17 17' stroke='%23fff' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      cursor: pointer;
    }

    .left-nav {
      left: 0px;
    }

    .right-nav {
      right: 0px;
      transform: rotate(180deg);
    }

    // max-width: calc(var(--dvw100) - 120px);
    position: relative;
    max-width: 1340px;
    width: calc(100%);
    @extend .flex-center;

    .modal-inner {
      width: 100%;
    }
  }

  app-profile {
    .ng-select-container {
      max-height: 12px;
      border-radius: 3px !important;
    }

    .ng-arrow-wrapper {
      padding-right: 31px !important;
    }
  }

  .ng-select.ng-select-single,
  .ng-select.ng-select-multiple {
    .ng-select-container {
      background-color: $white;

      .ng-arrow-wrapper {
        background-repeat: no-repeat;
        background-position: center;
        padding-right: 36px;
        height: 24px;
        width: 24px;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='%23A8884C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

        .ng-arrow {
          display: none;
        }
      }
    }

    &.ng-select-opened {
      .ng-select-container {
        .ng-arrow-wrapper {
          transform: rotate(180deg);
          // padding-left: 30px;
        }
      }
    }
  }

  app-toggle {
    height: 49px;

    .toggle-component {
      background-color: white !important;
    }
  }

  app-plus-minus-input {
    .plus,
    .minus {
      background-color: $primary20 !important;
    }
  }

  @media (max-width: $size_nosidebar) {
    top: 0px;
    left: 0px;
    padding-bottom: 50px;

    // align-items: flex-start;
    .modal-inner {
      margin-top: 10px;
      max-height: calc(var(--dvh100) - 150px);
    }
  }

  &.send-mail-modal {
    quill-editor {
      margin-top: 10px;
    }

    .buttons {
      margin-top: 15px;
    }

    label {
      color: $primary;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}

@media (max-width: $size_nosidebar) {
  // .admin-mode {
  .big-modal-fix {
    // .swipe-wrap {
    .modal-inner {
      overflow: scroll;
      min-width: unset;
      max-width: unset;
      position: relative;
      // width: calc(var(--dvw100) - 40px);
      height: calc(var(--dvh100) - 150px);

      & > * {
        position: absolute;
        top: 0px;
        left: 0px;
        // margin: 20px;
        padding: 15px;
        width: 780px !important;
        overflow: unset;
        // min-width: 100%;
      }
    }

    // }
    // }
  }
}

.no-sidebar {
  app-modal {
    @media (max-width: $size_nosidebar) {
      top: 0px;
      left: 0px;
      align-items: center;

      .modal-inner {
        margin-top: 10px;
        max-height: calc(var(--dvh100) - 150px);
      }
    }
  }
}

.berufserfahrung {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .erfahrungen-card {
    background-color: $primary10;
    border-radius: 12px;
    padding: 16px;
    display: flex;

    .left {
      flex-grow: 1;
      flex-basis: 50%;
    }

    app-svgs {
      flex-basis: 0;
      cursor: pointer;
    }
  }
}

// app-job-card{
//   background-color: $primary10;
// }

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: $primary;
}

.notiflix-loading-icon {
  svg {
    circle {
      stroke-width: 4.5;
    }
  }
}

.status {
  //badge
  width: fit-content;
  border-radius: 20px;
  background-color: $primary20;
  padding: 5px 16px 5px 5px;
  display: flex;
  margin: 9px 0;
  display: flex;
  gap: 10px;
  align-items: center;
}

.badge {
  @extend .flex-center;
  height: 26px;
  width: 26px;
  min-width: 26px;
  border-radius: 50%;

  background-color: $secondary;

  &.success {
    background-color: $success;
  }

  &.rejected {
    background-color: $fail;
  }

  &.primary120 {
    background-color: $primary120;
  }
}

.cm-wrap {
  position: relative;

  &::after {
    content: "cm";
    position: absolute;
    right: 15px;
    top: 15px;
    color: $primary40;
    background-color: $primary10;
  }

  &.kg {
    &::after {
      content: "kg";
    }
  }

  &.km {
    &::after {
      content: "km";
    }
  }

  &.eur {
    &::after {
      content: "€";
    }
  }

  &.eur_km {
    &::after {
      content: "€ / km";
    }
  }

  &.km {
    &::after {
      content: "km";
    }
  }

  &.h {
    &::after {
      content: "h";
    }
  }

  &.m {
    &::after {
      content: "m";
    }
  }
}

.swiper-slide {
  width: auto;
}

.text-with-icon {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.icon-left {
    p {
      margin-left: 5px;
    }
  }

  &.icon-right {
    p {
      margin-right: 5px;
    }
  }
}

.required-dot {
  background-color: $secondary;
  min-width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 6px;
}

.pointer-events-none {
  pointer-events: none;
}

.display-none {
  display: none !important;
}

.jobsuche {
  .mehr-infos {
    border-radius: 12px;
  }
}

.job-detail {
  .mehr-infos {
    border-radius: 0px 0px 12px 12px;
  }
}

.deactivated {
  opacity: 0.5;
  pointer-events: none;
  touch-action: none;
}

.user-deactivated {
  opacity: 0.6;
}

.user-deactivated-badge {
  margin-left: 3px;
  padding: 5px 10px;
  background-color: #636f7d;
  font-size: 12px;
  font-weight: normal;
  border-radius: 19px;
  position: relative;
  top: -2.5px;
}

.kuendigen-confirm-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  a {
    width: 100% !important;
    flex-grow: 1;
    margin: 0 !important;
  }
}

// dragula
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;

  // job-roles modal
  .inner {
    display: flex !important;
    background-color: $primary10 !important;
    border-radius: 12px !important;
    padding: 9px 12px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
  }

  // job-roles modal end
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

// dragula end

//modal fix for link insertion in summernote
.note-modal.link-dialog.open {
  .note-modal-content {
    display: flex;
    flex-direction: column;
  }

  .note-modal-footer {
    height: auto;
  }

  .checkbox {
    label {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.foto-guide-modal {
  .modal-inner {
    max-width: 450px !important;
  }
}

.dropdown-wrap {
  position: relative;

  .dropdown {
    position: absolute;
    top: calc(100% + 10px);
    z-index: 12;
    border-radius: 8px;

    div {
      background-color: $white;
      padding: 15px 30px;
      cursor: pointer;

      &:hover {
        color: $secondary;
      }

      &:first-child {
        // border-radius: 8px 8px 0px 0px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding-top: 30px;
      }

      &:last-child {
        // border-radius: 0px 0px 8px 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        padding-bottom: 30px;
      }

      white-space: nowrap;
    }
  }

  &.right {
    .dropdown {
      right: 0 !important;
    }
  }

  .personal-dropdown {
    right: 0px;

    @media (max-width: $mobile) {
      right: unset;
      left: 0px;
    }
  }
}

app-bewerbercheck-modal {
  app-arbeitsbeginn-status {
    top: -20px;
    position: relative;
  }

  .arbeitsbeginn-status {
    // background-color: white !important;
    .tags {
      label {
        background-color: white;
      }

      input:checked + label {
        background-color: $primary80;
      }
    }

    .additional-container {
      padding-top: 0px !important;

      h4 {
        margin-top: 15px;
      }
    }

    .sex-wrap {
      input {
        min-width: unset !important;
      }
    }
  }
}

// .copy-personal-modal {
.ng-select-multiple {
  height: fit-content;

  .ng-select-container {
    height: fit-content !important;
    min-height: 49px;
  }

  .ng-select-container .ng-value-container .ng-placeholder {
    top: 13px !important;
  }
}

// }

@media (max-width: $mobile) {
  .big-modal-fix {
    .left-nav,
    .right-nav {
      max-width: 35px;
    }

    .left-nav {
      left: -15px !important;
    }

    .right-nav {
      right: -15px !important;
    }

    .modal-inner {
      height: 90vh;
      max-height: 90vh;
      gap: 10px;

      .close-btn {
        position: fixed;
        top: 10px !important;
        right: 20px !important;
      }

      .header {
        margin-bottom: 0px;

        > h5 {
          font-size: 14px;
          margin-bottom: 4px;
        }

        > h4 {
          display: inline-block;
          font-size: 12px;
          margin-right: 5px;
        }

        > h2 {
          display: inline-block;
          font-size: 16px;
        }

        > .perso-nr-wrapper {
          margin-left: 5px;
        }
      }

      .navbar {
        gap: 15px;
        flex-wrap: wrap;
        max-width: 85vw;
      }

      .scroll-container {
        tr {
          > * {
            font-size: 14px;
          }

          h4 {
            margin-bottom: 3px;
          }

          td {
            max-width: unset;
            width: unset;
            min-width: unset;
            flex-basis: 150px;
          }
        }

        textarea,
        ng-select {
          max-width: 78vw;
        }
      }

      .buttons {
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 10px;
        max-width: 86vw;

        .left-buttons,
        .right-buttons {
          width: 100%;
          display: flex;
          gap: 10px;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          > app-button {
            width: 100%;
          }
        }
      }
    }

    .swipe-wrap {
      max-width: calc(100% - 55px);

      .modal-inner {
        .buttons {
          max-width: 77vw;
        }
      }
    }
  }
}
