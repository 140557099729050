// font weights
// 800 = bolder,
/* Webfont: Lato-Bold */
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Black.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Black.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Black.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Black.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Black.ttf") format("truetype");
  font-style: normal;
  font-weight: 900;
  text-rendering: optimizeLegibility;
}
// 700 = bold
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Bold.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Bold.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Bold.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
  text-rendering: optimizeLegibility;
}
// 400 = normal
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Regular.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Regular.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Regular.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Regular.ttf")
      format("truetype");
  font-style: normal;
  font-weight: 400;
  text-rendering: optimizeLegibility;
}
// 300 = light
@font-face {
  font-family: "Lato";
  src: url("assets/fonts/Lato-Light.eot"); /* IE9 Compat Modes */
  src: url("assets/fonts/Lato-Light.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/Lato-Light.woff2") format("woff2"),
    /* Modern Browsers */ url("assets/fonts/Lato-Light.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/Lato-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
  text-rendering: optimizeLegibility;
}
