// variables

$primary: #3c4b5d;
$primary120: #334051;
$primary90: #505d6d;
$primary80: #636f7d;
$primary60: #8a939e;
$primary40: #b1b7be;
$primary20: #d8dbdf;
$primary10: #ecedef;

$white: #ffffff;
$secondary: #a8884c;

$success: #85a84c;
$fail: #a84c4c;

$overlay_bg: rgba(60, 75, 93, 0.5);

$themeColours: (
  "primary": $primary,
  "primary120": $primary120,
  "primary90": $primary90,
  "primary80": $primary80,
  "primary60": $primary60,
  "primary40": $primary40,
  "primary20": $primary20,
  "primary10": $primary10,
  "white": $white,
  "secondary": $secondary,
  "success": $success,
  "fail": $fail,
  "overlay_bg": $overlay_bg,
);

//sizes
//when detail section desktop - right becomes a modal
$size_no_right_outlet: 1460px;

//when vertical nav goes to bottom
$size_nosidebar: 900px;

$mobile: 500px;

$last_element_margin_nosidebar: 170px;

$last_element_margin_sidebar: 20px;

@mixin shadowS {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
}

@mixin p-light {
  font-weight: 300 !important;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.01em;
}

@mixin link {
  font-weight: 700;
  font-size: 16px;
  // line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: -0.01em;
  color: $primary;
}

:root {
  --primary: #3c4b5d;
  --primary120: #7e7e7e;
  --primary90: #505d6d;
  --primary80: #636f7d;
  --primary60: #8a939e;
  --primary40: #b1b7be;
  --primary20: #d8dbdf;
  --primary10: #ecedef;

  --white: #ffffff;
  --secondary: #a8884c;
  --success: #85a84c;
  --fail: #a84c4c;
  --overlay_bg: $overlay_bg;

  --dvw100: 100vw;
  --dvh100: 100vh;
  --dvw80: 90vw;
  --dvh80: 90vh;
  --dvw80: 80vw;
  --dvh80: 80vh;

  @supports (width: 100dvw) and (height: 100dvh) {
    --dvw100: 100dvw;
    --dvh100: 100dvh;
    --dvw90: 90dvw;
    --dvh90: 90dvh;
    --dvw80: 80dvw;
    --dvh80: 80dvh;
  }
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

// Small < 578px devices
@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}